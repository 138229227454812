<template>
  <div class="main">
    <div class="page-box">
      <div class="page-title">部门</div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item class="breadcrumb-active"
          ><i class="fa fa-dashboard"></i>工作台</el-breadcrumb-item
        >
        <el-breadcrumb-item class="breadcrumb-active"
          >组织机构</el-breadcrumb-item
        >
        <el-breadcrumb-item class="breadcrumb-active"
          >组织机构</el-breadcrumb-item
        >
        <el-breadcrumb-item>部门</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="card-box">
      <div class="screen-box">
        <div class="screen-input-box">
          <div class="title-box">部门编码:</div>
          <el-input v-model="number" placeholder="请输入部门编码"></el-input>
        </div>
        <div class="screen-input-box">
          <div class="title-box">部门名称:</div>
          <el-input v-model="name" placeholder="请输入部门名称"></el-input>
        </div>

        <button class="btn-pre" @click="getDepartments(1)">
          <i class="fa fa-search"></i>查询
        </button>
        <button class="btn-add" @click="newBuilt" v-if="powers.add">
          <i class="fa ion-plus-round"></i>新建
        </button>
      </div>
      <div class="table-box">
        <el-table
          ref="doLayout"
          :data="tableData"
          height="500"
          border
          tooltip-effect="light"
          :header-cell-style="{ color: '#f5f5f5', background: '#23527c' }"
          row-key="ID"
          lazy
          :load="load"
          :tree-props="{ children: 'children', hasChildren: 'HasChildren' }"
          :empty-text="loading ? '加载中...' : '暂无数据'"
        >
          <el-table-column type="" label="#" width="55">
            <template slot-scope="scope">
              <el-checkbox></el-checkbox>
            </template>
          </el-table-column>
          <el-table-column prop="ID" label="部门id" width="80" />
          <el-table-column prop="DepartmentCHS" label="中文名称" width="80" />
          <el-table-column prop="DepartmentEN" label="英文名称" width="80" />
          <el-table-column prop="Company.ID" label="上级单位id" width="60" />
          <el-table-column
            prop="Company.CompanyCHS"
            label="上级单位"
            width="80"
          />
          <el-table-column prop="DeptNumber" label="部门编码" width="80" />
          <el-table-column prop="Abbreviation" label="缩写" width="80" />
          <el-table-column
            prop="SubjectTypeName"
            label="主体分类名称"
            width="80"
          />
          <el-table-column label="操作" width="80">
            <template slot-scope="scope">
              <i
                class="fa fa-edit"
                v-if="powers.update"
                @click="edit(scope.row)"
              ></i>
              <i
                class="fa fa-trash"
                v-if="powers.delete"
                @click="deleteDepartments(scope.row)"
              ></i>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          @size-change="getDepartments(1)"
          @current-change="getDepartments"
          :current-page.sync="page"
          :page-size.sync="size"
          :page-sizes="[10, 20, 50, 100]"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      :title="dialogType === 1 ? '新建部门' : '编辑部门'"
      :visible.sync="dialogVisible"
      width="800px"
      :before-close="handleClose"
      top="13vh"
    >
      <div class="dialog-main">
        <div class="line-box">
          <div class="from-box">
            <div class="name">中文名称:</div>
            <el-input
              v-model="parameter.NameCHS"
              placeholder="中文名称"
            ></el-input>
          </div>

          <div class="from-box">
            <div class="name">英文名称:</div>
            <el-input
              v-model="parameter.NameEN"
              placeholder="英文名称"
            ></el-input>
          </div>
        </div>
        <div class="line-box">
          <div class="from-box">
            <div class="name">上级单位:</div>
            <el-select
              v-model="parameter.CompanyId"
              placeholder="选择上级单位"
              filterable
              clearable
            >
              <el-option
                v-for="item in options1"
                :key="item.ID"
                :label="item.CompanyCHS"
                :value="item.ID"
              >
              </el-option>
            </el-select>
          </div>

          <div class="from-box">
            <div class="name">部门编码:</div>
            <el-input
              v-model="parameter.Number"
              placeholder="部门编码"
            ></el-input>
          </div>
        </div>
        <div class="line-box">
          <div class="from-box">
            <div class="name">日期:</div>
            <el-date-picker
              :picker-options="pickerOptionsStart"
              v-model="parameter.Dateline"
              value-format="yyyy-MM-dd"
              type="date"
              placeholder="选择日期"
            >
            </el-date-picker>
          </div>

          <div class="from-box">
            <div class="name">是否启用:</div>
            <el-switch v-model="parameter.Enable"> </el-switch>
          </div>
        </div>
        <div class="line-box">
          <div class="from-box">
            <div class="name">缩写:</div>
            <el-input
              v-model="parameter.Abbreviation"
              placeholder="缩写"
            ></el-input>
          </div>

          <div class="from-box">
            <div class="name">主体分类名称:</div>
            <el-input
              v-model="parameter.SubjectTypeName"
              :disabled="dialogType === 3"
              placeholder="主体分类名称"
            ></el-input>
          </div>
        </div>

        <div class="line-box">
          <div class="single">
            <div class="name">上级部门:</div>
            <el-cascader
              v-model="parameter.ParentIds"
              :options="tree"
              :props="props"
              clearable
            ></el-cascader>
          </div>
        </div>
      </div>
      <div class="dialog-footer">
        <el-button class="pop-close" @click="handleClose">取消</el-button
        ><el-button class="pop-save" @click="addUpRoles" v-if="dialogType !== 3"
          >保存</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { auditRecords } from "@/utils/tableData";
import {
  getDepartments,
  getDepartmentsChildren,
  getDepartmentsTree,
  addDepartments,
  updateDepartments,
  deleteDepartments,
  getCompanies,
} from "@/api/user";
export default {
  data() {
    return {
      tableData: [],
      multipleSelection: [],
      auditRecords: auditRecords,
      dialogVisible: false,
      dialogType: 1, //1新建2、编辑3、查看
      options1: null,
      options2: [
        {
          value: 1,
          label: "展示",
        },
        {
          value: 2,
          label: "不展示",
        },
      ],
      senior: false,
      value1: null,
      value2: null,
      value3: null,
      page: 1,
      size: 10,
      total: null,
      number: null,
      name: null,
      Id: 0,
      ParentId: 0,
      DepartmentId: 0,
      parameter: {
        Id: 0,
        CompanyId: null,
        Code: "",
        NameCHS: "",
        NameEN: "",
        Enable: true,
        State: 1,
        Number: "",
        Abbreviation: "",
        Dateline: "",
        SubjectTypeName: "",
        ParentIds: [],
      },
      tree: [],
      props: {
        value: "ID",
        label: "DepartmentCHS",
        children: "Children",
        checkStrictly: true,
      },
      treeObj: {},
      pickerOptionsStart: {
        disabledDate: (time) => {
          return time.getTime() < Date.parse("1899-12-31");
        },
      },
      powers: {
        add: false,
        update: false,
        delete: false,
        download: false,
        import: false,
        export: false,
        print: false,
        examine: false,
        upload: false,
        resetting: false,
      },
      loading: true,
    };
  },
  filters: {
    timeFormater(value) {
      value = value.substring(0, 10);
      return value;
    },
  },
  methods: {
    deleteItem(index) {
      this.messageList.splice(index, 1);
    },
    handleNoticeClose() {
      this.noticeDrawer = false;
    },

    handleClose() {
      this.dialogVisible = false;
      this.parameter = {
        Id: 0,
        CompanyId: null,
        Code: "",
        NameCHS: "",
        NameEN: "",
        Enable: true,
        State: 1,
        Number: "",
        Abbreviation: "",
        Dateline: "",
        SubjectTypeName: "",
        ParentIds: [],
      };
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },

    newBuilt() {
      this.dialogVisible = true;
      this.dialogType = 1;
      this.roleName = "";
      this.enable = false;
    },
    edit(row) {
      this.dialogVisible = true;
      this.dialogType = 2;
      const multipleSelectionItem = row;
      this.parameter = {
        Id: multipleSelectionItem.ID,
        CompanyId: multipleSelectionItem.CompanyID,
        Code: "",
        NameCHS: multipleSelectionItem.DepartmentCHS,
        NameEN: multipleSelectionItem.DepartmentEN,
        Enable: multipleSelectionItem.IsEnabled === 1 ? true : false,
        State: multipleSelectionItem.State,
        Number: multipleSelectionItem.DeptNumber,
        Abbreviation: multipleSelectionItem.Abbreviation,
        Dateline: multipleSelectionItem.Dateline,
        SubjectTypeName: multipleSelectionItem.SubjectTypeName,
        ParentIds: [],
      };
      if (multipleSelectionItem.ParentIds) {
        const ParentIds = multipleSelectionItem.ParentIds.split(",");
        ParentIds.forEach((item) => {
          let newItem = parseInt(item);
          this.parameter.ParentIds.push(newItem);
        });
      }
    },

    doLayout() {
      this.$nextTick(() => {
        this.$refs.doLayout.doLayout();
      });
    },

    //获取部门列表
    getDepartments(type) {
      if (type) {
        this.page = type;
      }
      this.loading = true;
      const data = {
        page: this.page,
        size: this.size,
        isRoot: true,
        number: this.number,
        name: this.name,
      };
      getDepartments(data).then((res) => {
        if (res.status === 200) {
          this.tableData = res.response.data;
          this.total = res.response.dataCount;
          // this.doLayout();
        }
        this.loading = false;
      });
    },
    //获取子选项
    load(tree, treeNode, resolve) {
      this.treeObj[tree.ID] = { tree, treeNode, resolve };
      getDepartmentsChildren({ id: tree.ID }).then((res) => {
        if (res.status === 200) {
          if (res.response.length === 0) {
            this.resetLazyTree();
          }
          resolve(res.response);
        }
      });
    },
    resetLazyTree() {
      this.$set(this.$refs.doLayout.store.states, "lazyTreeNodeMap", {});
    },
    //添加编辑部门
    addUpRoles(type) {
      if (this.dialogType === 1) {
        //新增
        addDepartments(this.parameter).then((res) => {
          if (res.status === 200) {
            this.$message.success("创建成功");
            this.getDepartments();
            this.getTree();
            this.handleClose();
            for (let i in this.treeObj) {
              const { tree, treeNode, resolve } = this.treeObj[i];
              this.load(tree, treeNode, resolve);
            }
          }
        });
      } else {
        updateDepartments(this.parameter).then((res) => {
          if (res.status === 200) {
            this.$message.success("编辑成功");
            this.getDepartments();
            this.getTree();
            this.handleClose();

            for (let i in this.treeObj) {
              const { tree, treeNode, resolve } = this.treeObj[i];
              this.load(tree, treeNode, resolve);
            }
          }
        });
      }
    },
    //删除部门
    deleteDepartments(row) {
      let ids = [];

      ids.push(row.ID);

      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteDepartments(ids).then((res) => {
          //
          if (res.status === 200) {
            this.getDepartments();
            this.getTree();
            for (let i in this.treeObj) {
              const { tree, treeNode, resolve } = this.treeObj[i];
              this.load(tree, treeNode, resolve);
            }
            this.$nextTick(() => {
              this.$message.success("删除成功");
            });
          }
        });
      });
    },

    getTree() {
      getDepartmentsTree().then((res) => {
        if (res.status === 200) {
          this.tree = res.response;
        }
      });
    },
    getButtonPower() {
      if (!this.$store.state.userPower) {
        this.$store.commit(
          "changeUserPower",
          JSON.parse(localStorage.getItem("userPower"))
        );
      }

      const item = this.$store.state.userPower.find((item) => {
        return item.Name === this.$route.meta.title;
      });
      const actions = item.Actions;

      actions.forEach((element) => {
        switch (element.Id) {
          case 1: //增加
            this.powers.add = true;
            break;
          case 2: //删除
            this.powers.delete = true;
            break;
          case 3: //修改
            this.powers.update = true;
            break;
          case 5: //下载
            this.powers.download = true;
            break;
          case 6: //导入
            this.powers.import = true;
            break;
          case 7: //导出
            this.powers.export = true;
            break;
          case 8: //打印
            this.powers.print = true;
            break;
          case 9: //审批
            this.powers.examine = true;
            break;
          case 10: //上传
            this.powers.upload = true;
            break;
          case 11: //重置
            this.powers.resetting = true;
            break;
        }
      });
    },
  },

  created() {
    this.getDepartments();
    this.getTree();
    getCompanies({ page: 1, size: 1000000 }).then((res) => {
      if (res.status === 200) {
        this.options1 = res.response.data;
      }
    });
    this.getButtonPower();
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;
  padding: 24px;
  box-sizing: border-box;
  background-color: #f7f8fa;
  .page-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .page-title {
      font-size: 18px;
      color: #000000;
    }
    .breadcrumb-active {
      color: #303133;
      font-weight: bolder;
      cursor: pointer;
      .fa {
        margin-right: 5px;
      }
    }
  }
  .card-box {
    width: 100%;
    // min-height: calc(100% - 48px);
    // height: calc(100% - 48px);
    background: #ffffff;
    border-radius: 2px 2px 2px 2px;
    opacity: 1;
    margin-top: 24px;
    box-sizing: border-box;
    padding: 24px 0;
    .screen-box {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      position: relative;
      padding: 0 12px;
      .el-input {
        width: 150px;
        margin-right: 24px;
      }
      .el-select {
        width: 150px;
        margin-right: 24px;
      }
      .el-button {
        margin-left: 24px;
      }
      .senior {
        position: absolute;
        width: 100%;
        top: 40px;
        left: 0;
        background: #fefefe;
        border: 1px solid #eae8e8;
        border-top-width: 1px;
        border-top-style: solid;
        border-top-color: rgb(234, 232, 232);
        box-sizing: border-box;
        z-index: 999;
        padding: 0 24px 24px 24px;
        border-top: 0;
        box-shadow: 0 7px 18px -12px #bdc0bb;
        .from-boxs {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          .from-box {
            margin-top: 24px;
            .name {
              margin-bottom: 10px;
            }
            .el-select {
              width: 150px;
            }
            .el-input {
              width: 150px;
            }
          }
        }
        .buttons {
          display: flex;
          justify-content: flex-end;
          margin-top: 24px;
        }
      }
    }
    .table-box {
      width: 100%;
      // height: calc(100% - 65px);
      .el-table {
        margin-top: 24px;
        // height: calc(100% - 56px);
        min-height: 500px;
        box-sizing: border-box;
      }
      .el-pagination {
        margin-top: 24px;
      }
      .table-color {
        color: #0076ff;
      }
      .buttons {
        display: flex;
      }
    }
  }
  .dialog-main {
    border-bottom: 2px solid #e6e6e6;
    padding: 12px 24px 0 24px;
    width: 100%;
    box-sizing: border-box;
    .line-box {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .from-box {
        display: flex;
        align-items: center;
        width: 340px;
        margin-bottom: 24px;
        .name {
          width: 80px;
          font-size: 16px;
          flex-shrink: 0;
          margin-right: 10px;
        }
        .el-input {
          width: 250px;
        }
        .el-select {
          width: 250px;
        }
      }
      .single {
        display: flex;
        width: 100%;
        align-items: center;
        margin-bottom: 24px;
        .name {
          flex-shrink: 0;
          width: 80px;
          margin-right: 10px;
        }
        .el-cascader {
          width: 100%;
        }
      }
    }
  }
  .dialog-footer {
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
<style scoped lang='scss'>
// el-table表格对齐
.el-table ::v-deep .el-table__row:not([class*="el-table__row--level-"]) {
  td:nth-child(2) {
    padding-left: 24px !important; //一级数据无Child缩进
  }
}
.el-table ::v-deep .el-table__placeholder {
  margin-left: 3px; //子节点无Child缩进
}
</style>
<style lang="scss" scoped>
::v-deep .el-table {
  width: 100%;
  .el-table__header-wrapper table,
  .el-table__body-wrapper table {
    width: 100% !important;
  }
  .el-table__body,
  .el-table__footer,
  .el-table__header {
    table-layout: auto;
    .yincang {
      width: 100px !important;
      min-width: 100px !important;
      max-width: 100px !important;
      .cell {
        width: 100%;
        box-sizing: border-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit !important;
        white-space: nowrap !important;
      }
    }
  }
  .el-table__header {
    .yincang {
      width: 100px !important;
      min-width: 100px !important;
      max-width: 100px !important;
      .cell {
        width: 100%;
        box-sizing: border-box !important;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: inherit !important;
        white-space: nowrap !important;
      }
    }
  }
}
</style>
<style scoped>
.el-dialog__wrapper >>> .el-dialog__body {
  padding: 0;
  overflow: auto;
  border-top: 2px solid #e6e6e6;
}
.el-dialog__wrapper >>> .my-label {
  background: #f5f7fa !important;
}
.el-textarea >>> .el-textarea__inner {
  min-height: 100px !important;
}
.el-table >>> .el-table__placeholder {
  width: 23px;
}

.el-table >>> th.el-table__cell > .cell {
  font-weight: bolder;
  font-size: 12px;
  text-align: center;
  font-family: "Microsoft YaHei";
}

.el-table >>> th.el-table__cell {
  padding: 6px 0;
}
.table-box >>> .el-table__empty-block {
  width: 100% !important;
}

.el-table >>> .el-table__body .cell {
  text-align: center;
  font-size: 12px;
  color: #333;
}
.el-table >>> .el-table__cell {
  padding: 6px 0;
}
.approval {
  display: inline;
  padding: 2px 5px;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 3px;
  font-size: 12px;
}
.label-success {
  background-color: #00a65a;
}
.label-warning {
  background-color: #f39c12;
}
.fa {
  margin-right: 10px;
  color: #3c8dbc;
  cursor: pointer;
}
.fa:hover {
  color: #72afd2;
}
.screen-box >>> .el-input__inner {
  height: 34px;
  border-color: #d2d6de;
  border-radius: 0;
}
.screen-box >>> .el-input__inner:focus {
  height: 34px;
  border-color: #61a2c9;
  border-radius: 0;
}
</style>
